//
// IMPORTS
//

@import "variables";

body {
	line-height: 1;
}

// Syntax highlighting @import is at the bottom of this file
// Settled on moving the import of syntax highlighting to the bottom of the CSS
// ... Otherwise it really bloats up the top of the CSS file and makes it difficult to find the start
@import "highlights";
@import "svg-icons";
